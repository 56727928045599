import React from 'react';
import { PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID } from 'gatsby-env-variables';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import {
  WaterproofMattressProtectorProductSection,
  WaterproofMattressProtectorAccordionSection,
  WaterproofMattressProtectorIntroSection,
  MeetOurHappySleepersSection,
  WoosaPromiseIconGridSection,
  CompleteYourNightsSection,
  WaterproofMattressProtectorReviewGridSection,
  WaterproofMattressProtectorBenefitSection,
  WaterproofMattressProtectorProductFixedBottomBar,
} from '../../../components/sections';
import { CountryCode } from '../../../utils/constants';

const WaterproofMattressProtectorPage: React.FC = () => {
  if (!PRODUCT_WATERPROOF_MATTRESS_PROTECTOR_ID) return null;

  return (
    <Layout countryCode={CountryCode.SG}>
      <SEO
        title="Waterproof Mattress Protector"
        description="Get more sleeps out of your mattress. Chase your zzz's without worrying about spills."
      />
      <WaterproofMattressProtectorProductSection />
      <WaterproofMattressProtectorAccordionSection />
      <WaterproofMattressProtectorIntroSection />
      <WaterproofMattressProtectorBenefitSection />
      <MeetOurHappySleepersSection />
      <WoosaPromiseIconGridSection />
      <CompleteYourNightsSection type="WaterproofMattressProtector" />
      <WaterproofMattressProtectorReviewGridSection />
      <WaterproofMattressProtectorProductFixedBottomBar />
    </Layout>
  );
};

export default WaterproofMattressProtectorPage;
